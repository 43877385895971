// form
.applyform-container {
    h1 {
        margin-bottom: rem-calc(20);
    }
}
.form-container{
    position: relative;
    max-height: 0;
    overflow: hidden;
    background-image: url('/assets/images/form-bg.jpg');
	background-size: cover;
	background-position: top center;
	background-repeat: no-repeat;
    background-color: transparent;
    transition: 0.5s max-height ease, 0.5s opacity ease;
    &.active{
        max-height:1000px;
    }
    &.apply{
        overflow: visible;
        max-height:100%;
        background-image: none;
        background-color: $dark-gray;
        p{
            @include breakpoint(small){
                font-size: rem-calc(12);
                line-height: rem-calc(16);
            }
            a{
                color: $white;
                text-decoration: underline;
            }
        }
        .form-button{
            text-align: left;
        }
        .doc-text {
            @include breakpoint(small){
                font-size: rem-calc(16);
                line-height: rem-calc(18);
            }
            @include breakpoint(medium){
                font-size: rem-calc(18);
                line-height: rem-calc(20);
            }            
        }
        
        .doc-link {
            position: relative;
            color: $white;
            display: block;                
            margin-bottom: rem-calc(16);
            padding: rem-calc(20);
            background: $penna-navy;
            &:hover{
                .doc-link-text {
                    text-decoration: underline;
                    color: $white;
                }                
            }
            .doc-icon {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left:rem-calc(16);
                display: inline-block;
                width: rem-calc(30);
            }
            .doc-link-text {
                display: inline-block;                
                padding-left:rem-calc(42);
                font-weight: bold;
                @include breakpoint(small){
                    font-size: rem-calc(16);
                    line-height: rem-calc(18);
                }
                @include breakpoint(medium){
                    font-size: rem-calc(18);
                    line-height: rem-calc(20);
                }
            }    
        }
    }
}

// form error
.apply-form {
    fieldset {
        border: none;
        padding: 0;
        legend {
            font-weight: bold;
            @include breakpoint(small){
                font-size: rem-calc(14);
                line-height: rem-calc(20);
                margin-bottom: rem-calc(8);
            }
            @include breakpoint(medium){
                font-size: rem-calc(16);
                line-height: rem-calc(22);
            }
            @include breakpoint(large){
                font-size: rem-calc(20);
                line-height: rem-calc(28);
            }
        }
    }
    .error {
        display: inline-block;
        color: #E21C21;
        font-size: rem-calc(12);
        margin-bottom: rem-calc(8);
    }
    .form-checkbox {
        position: relative;
        input{
            display: block;
            margin:0;
        }
        label.error{
            display: block;
            margin-top:rem-calc(8);
        }
    }

}

.file-uploaded {
    background-color: $success-color!important;
    color: $white;
}

.spinner {
    opacity: 0;
    -webkit-animation: rotate 2s linear infinite;
            animation: rotate 2s linear infinite;
    z-index: 2;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -25px 0 0 -25px;
    width: rem-calc(50);
    height: rem-calc(50);
    transition: 0.5s opacity ease;

    &.show-spinner {
        opacity: 1;
    }
  }
  .spinner .path {
    stroke: #cccccc;
    stroke-linecap: round;
    -webkit-animation: dash 1.5s ease-in-out infinite;
            animation: dash 1.5s ease-in-out infinite;
  }
  
  @-webkit-keyframes rotate {
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  
  @keyframes rotate {
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  @-webkit-keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }

// form label
.form-label{
    color: $white;
    @include breakpoint(small){
        display: inline-block;
        font-size: rem-calc(12);
        line-height: rem-calc(12);
        vertical-align: middle;
    }
    @include breakpoint(medium){
        font-size: rem-calc(14);
        line-height: rem-calc(14);
    }    
}

// form input
.form-input{
    background-color: $light-gray;
    font-size: rem-calc(12);
    line-height: rem-calc(12);
    width:100%;
    margin-bottom: rem-calc(4);
    padding: rem-calc(7) rem-calc(8);
    border:none;
    &.error {
        color: black;
        outline: 1px solid #E21C21;
        margin-bottom: rem-calc(4);
    }
}

// form select
.form-select{
    background-color: $light-gray;
    font-size: rem-calc(12);
    line-height: rem-calc(12);
    padding: rem-calc(7) rem-calc(8);
    text-align: center;
    border:none;
    margin-bottom: rem-calc(4);
    cursor: pointer;
    option{
        background-color: $light-gray;
        text-align: center;
    }
}

// form file
.hidden-input{
    display: block!important;
    width: 0;
    height:0;
    opacity:0;
    margin:0!important;
    overflow: hidden;
}

.input-button{
    display: block;
    background-color: $light-gray;
    width:100%;
    text-align: center;
    font-size: rem-calc(12);
    line-height: rem-calc(12);
    padding: rem-calc(7) rem-calc(8);
    cursor: pointer;
    margin-bottom: rem-calc(4);
}

// form checkbox

.form-checkbox {
    @include breakpoint(small){
        text-align: center;
        margin-bottom: rem-calc(20);
    }
    @include breakpoint(large){
        text-align: left;
        margin-bottom: 0;
    }
}

.form-checkbox-label {
    cursor: pointer;
    display: inline-block;
    position: relative;
    padding-left: rem-calc(30);
    color: $white;
    font-size: rem-calc(12);
    line-height: rem-calc(19);
    letter-spacing: 0.015em;
    margin-right: rem-calc(20);  
  &:before{
    content: "";
    width: rem-calc(19);
    height: rem-calc(19);
    position: absolute;  
    left: 0;
    background-image: url('/assets/images/checkbox.png');
    background-repeat: no-repeat;
    background-size: contain; 
  }
}

input[type=checkbox] {
    display: flex;
    width: 0;
    opacity: 0; 
}

input[type=radio] {
    display: inline-block;
    width: 0;
    opacity: 0;
    margin-left:-5px;
}

input[type=checkbox]:checked + label:before {
    background-image: url('/assets/images/checkbox-ticked.png');
    background-repeat: no-repeat;
    background-size: contain;      
}

input[type=radio]:checked + label:before {
    background-image: url('/assets/images/checkbox-ticked.png');
    background-repeat: no-repeat;
    background-size: contain;      
}

input[type=checkbox]:focus ~ .form-checkbox-label:before, input[type=radio]:focus + .form-checkbox-label:before, input[type=file]:focus ~ .input-button {
    outline: 1px solid #4d90fe;  
}

label {
    a {
        color: $white;
        text-decoration: underline;
        &:hover, &:focus {
            color: $white;
        }
    }
}

// form subtitle

.form-subtitle{
    font-weight: bold;
    margin-bottom: rem-calc(8);
}

// form button

.form-button-text {
    @include breakpoint(small){
        margin: rem-calc(30) 0;
    }
    @include breakpoint(large){
        margin-top:rem-calc(50);
        margin-bottom: 0;        
    }   
}

.form-button{
    @include breakpoint(small){
        margin: rem-calc(20) 0;
        text-align: center;
    }
    @include breakpoint(large){
        margin-top:rem-calc(20);
        margin-bottom: 0;        
    }    
    .button{
        display: inline-block;
        width:48%;
        margin-right: 2%;
        font-size: rem-calc(15);
        line-height: rem-calc(15);
        padding: rem-calc(12) rem-calc(24);
        &.cancel{
            border:2px solid $white;
            background-color: $dark-gray;
            margin-right: 0;
        }
    }
}

// form messages

.hide-form {
    height:rem-calc(300);
    opacity: 0;
    pointer-events: none;
    overflow: hidden;
}

.form-message {
    display: none;
    h2{
        margin:0;
    }
}

.submit-error {
    display: none;
    &.show-error {
        display: block;
        margin-top:rem-calc(20);
    }
}

.show-message {
    display: block;
}