// base

main{
	overflow-y: hidden;
}

.divider{
	display: block;
	max-width: 94%;
	margin: 100px auto;
	width:100%;
	height:2px;
	background-color: $black;
}

.grid-relative{
	position: relative;
}

// back to top button
#topbutton {
	display: inline-block;
	background-color: $primary-color;
	width: 50px;
	height: 50px;
	text-align: center;
	border-radius: 4px;
	position: fixed;
	transition: background-color .3s, 
	opacity .5s, visibility .5s;
	opacity: 0;
	visibility: hidden;
	z-index: 1000;
	@include breakpoint(small){
		bottom: 16px;
		right: 16px;
	}
	@include breakpoint(large){
		bottom: 30px;
		right: 30px;
	}
	span{
		position: absolute;
		top: 15px;
		left:15px;
		display: inline-block;
		vertical-align: middle;
		color: $white;
		box-sizing: border-box;
		width: 20px;
		height: 20px;
		border-width: 4px 4px 0 0;
		border-style: solid;
		transform: rotate(-45deg);		
		&:after, &:before {
		  content: "";
		  box-sizing: border-box;
		}
		&:before{
			right: 0;
			top: -3px;
			position: absolute;
			height: 4px;
			box-shadow: inset 0 0 0 32px;
			transform: rotate(-45deg);
			width: 23px;
			transform-origin: right top;
		}
	}
	&:hover {
		cursor: pointer;
		background-color: #333;
	}
	&:active {
		background-color: #555;
	}
	&.show {
		opacity: 1;
		visibility: visible;
	}	
}

// transition all
.transition-all{
	transition: all 0.25s ease;
}

// old browser
.old-browser{
	position: fixed;
	top:0;
	left:0;
	width:100%;
	height:100%;
	background-color: $white;
	z-index: 1000;
	text-align: center;
	.logo{
		display: block;
		padding: 50px 0;
	}
	.browserupgrade{
		a{
			color: $primary-color;
		}
	}
}

// casengo chat bot
#casengo-vipbtn{
	.casengo-display-on{
		min-width: 50px!important;
		left: initial!important;
		@include breakpoint(small){
			right: 16px!important;
			bottom: 80px!important;
		}
		@include breakpoint(large){
			right: 30px!important;
			bottom: 100px!important;	
		}
	}

}