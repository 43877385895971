.header{
	background-image: url('/assets/images/header-bg.jpg');
	background-size: cover;
	background-position: top center;
	background-repeat: no-repeat;
	background-color: $black;
	.logo{
		display: block;
		max-width: rem-calc(132);
	}
	.header-title{
		letter-spacing: -0.045em;				
	}
	.header-break{		
		@include breakpoint(small){
			display: none;
		}
		@include breakpoint(medium){
			display: block;
		}				
	}
	.personalform{
		cursor: pointer;
	}
}