@font-face {
    font-family: 'Metropolis';
    src: url('/assets/fonts/Metropolis-Regular.otf');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Metropolis';
    src: url('/assets/fonts/Metropolis-Light.otf');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Metropolis';
    src: url('/assets/fonts/Metropolis-Medium.otf');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Metropolis';
    src: url('/assets/fonts/Metropolis-Bold.otf');
    src: url("/assets/fonts/Metropolis-Bold.eot?#iefix") format("embedded-opentype"), // IE6-IE8
    //url("/fonts/Metropolis-Bold.svg") format("svg"), // Legacy iOS
    url("/assets/fonts/Metropolis-Bold.ttf") format("truetype"), // Safari, Android, iOS /
    url("/assets/fonts/Metropolis-Bold.woff") format("woff"); // Modern Browsers
    //url("/fonts/Metropolis-Bold.woff2") format("woff2"); // Modern Browsers
    font-weight: 700;
    font-style: normal;
}