footer{
    background-color: $penna-navy;
    color: $white;
    #map-canvas{
        height:100%;
    }
    .map-canvas {
        display: none;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: rem-calc(550);
        padding: 0;
        width: 100%;
        &.current {
            display: block;
        }
    }    
    .footer-content{
        padding: rem-calc(40);
        .tabs{
            list-style: none;
            margin:0;
            li{
                display: inline-block;
                margin-right:rem-calc(15);
                cursor: pointer;
                font-size: rem-calc(18);
                &.current{
                    font-weight: bold;    
                }
                a{
                    color: $white;
                    &:hover{
                        color: $white;
                    }
                }
            }
        }
        .tab-content{
            display: none;
            margin-top:rem-calc(35);
            opacity: 0;
            visibility: hidden;            
            p, a {
                font-size: rem-calc(20);
                line-height: rem-calc(30);
            }
            a{
                color: $white;
            }
            &.current{
                display: block;
                opacity: 1;
                visibility: visible;
            }
        }
        .footer-social, .footer-copyright{
            margin-top: rem-calc(25);
            p{
                font-size: rem-calc(20);
                line-height: rem-calc(30);
            }
            a{
                display: inline-block;
                color: $white;
                font-size: rem-calc(12);
                line-height: rem-calc(30);
                margin-right: rem-calc(20);
            }
        }        
    }

}