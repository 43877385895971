// Base Colors

// background colors
.b-c-white {
    background-color: $white;
}
.b-c-black {
    background-color: $black;
}
.b-c-primary {
    background-color: $primary-color;
}
.b-c-secondary {
    background-color: $secondary-color;
}
.b-c-light-gray{
    background-color: $light-gray;
}
.b-c-medium-gray{
    background-color: $medium-gray;
}
.b-c-dark-gray{
    background-color: $dark-gray;
}
.b-c-dark-blue{
    background-color: $dark-blue;
}

// font colors
.f-c-white{
    color: $white;
}
.f-c-black{
    color: $black;
}
.f-c-primary{
    color: $primary-color;
}
.f-c-secondary{
    color: $secondary-color;
}


// Brand Colors
.brand-color{
    &.teal{
        background-color: $brand-color-teal;
    }
    &.green{
        background-color: $brand-color-green;
    }
    &.yellow{
        background-color: $brand-color-yellow;
    }
    &.aqua{
        background-color: $brand-color-aqua;
    } 
    &.navy{
        background-color: $brand-color-navy;
    } 
    &.magenta{
        background-color: $brand-color-magenta;
    } 
    &.lightblue{
        background-color: $brand-color-lightblue;
    } 
    &.silver{
        background-color: $brand-color-silver;
    }                    
}

// alert colors
.alert-color{
    color: $alert-color;
}