/* Buttons */

.button{
    background-color: $penna-navy;
    display: block;
    border:2px solid $penna-navy;
    font-size: rem-calc(21);
    line-height: rem-calc(22);
    padding: rem-calc(8) rem-calc(24);
    font-weight: 700;
    max-width: rem-calc(200);
    width:100%;
    margin: 0 auto;
    &:hover{
        background-color: $dark-gray;
    }
    &.no-margin {
        margin: 0;
    }
}

// plus icon

.icon {
    display: inline-block;
    position: relative;
    width:rem-calc(28);
    height:rem-calc(28);

    &.icon-plus {
    background-color: $dark-gray;
    border:2px solid $dark-gray;
    border-radius:50%;
    transition: background-color 0.25s ease;
        &:before, &:after {
            content:"";
            @include vertical-center();
            left:0;
            right:0;
            margin: 0 auto;
            background-color: $white;
            transition: background-color 0.25s ease;
        }
        &:after{        
            width: rem-calc(12);
            height: rem-calc(2);        
        }
        &:before{
            width: rem-calc(2);
            height: rem-calc(12);
        }
    }
}

/* Landing button */
.cta-btn {
    display: inline-block;
	border-radius: 50%;
    position: relative;
	cursor: pointer;
	box-shadow: 
		inset 0 0 0 0 rgba(255,255,255, 0.4),
		inset 0 0 0 1px rgba(255,255,255,0.6),
		0 1px 2px rgba(0,0,0,0.1);
    transition: all 0.4s ease-in-out;       
    span{
        color: $white;
        @include vertical-center();
        left:0;
        right:0;
        &.icon-plus{
            &:before, &:after {
                content:"";
                @include vertical-center();
                left:0;
                right:0;
                margin: 0 auto;
                background-color: $white;
                transition: background-color 0.25s ease;
            }
            &:after{        
                width: rem-calc(12);
                height: rem-calc(2);        
            }
            &:before{
                width: rem-calc(2);
                height: rem-calc(12);
            }           

        }
    }
    &:before{
        display: block;
        content: "";
        border-radius: 50%;
        background-color: $primary-color;
        opacity: 0.8;    
        position: absolute;
        top: 15%;
        left: 15%;
        width: 70%;
        height: 70%;
    }
    &:after{
        display: block;
        content: "";
        border-radius: 50%;
        position: absolute;
        top: 25%;
        left: 25%;
        width: 50%;
        height: 50%;
        border: 1px solid rgba(255,255,255,0.6);
    }
    &:before, &:after{
        transition: all 0.4s ease-in-out;
    }
    &.no-shadow{
        box-shadow: none;
    }
    &.small{
        width: 50px;
        height: 50px;
        &:before{
            top: 11%;
            left: 11%;
            width: 78%;
            height: 78%;
        }
        &:after{
            top: 21%;
            left: 21%;
            width: 58%;
            height: 58%;
        }         
    }
    &.medium{
        width: 60px;
        height: 60px;
        &:before{
            top: 11%;
            left: 11%;
            width: 78%;
            height: 78%;
        }
        &:after{
            top: 21%;
            left: 21%;
            width: 58%;
            height: 58%;
        }        
    }
    &.large{
        @include breakpoint(small){
            width: 25vw;
            height: 25vw;
            @include breakpoint(landscape){
                width: 10vw;
                height: 10vw;
            }
        }
        @include breakpoint(medium){
            width: 100px;
            height: 100px;
            @include breakpoint(landscape){
                width: 8vw;
                height: 8vw;
            } 
        }
        @include breakpoint(large){
            width: 100px;
            height: 100px;
        }
        @include breakpoint(xlarge){
            width: 126px;
            height: 126px;
        }
        span{
            @include breakpoint(small){
                font-size: 4vw;
                @include breakpoint(landscape){
                    font-size: 2vw;
                } 
            }
            @include breakpoint(medium){
                font-size: 16px;
                @include breakpoint(landscape){
                    font-size: 2vw;
                }
            }
            @include breakpoint(large){
                font-size: 16px;
            }
        }
        .icon-plus{
            &:before{
                height:15px;
            }
            &:after{
                width:15px;
            }            
        }
        &:before{
            top: 11%;
            left: 11%;
            width: 78%;
            height: 78%;
        }
        &:after{
            top: 21%;
            left: 21%;
            width: 58%;
            height: 58%;
        }        
    }
    
    .cta-bg{
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        opacity: 0;        
        transition: all 0.4s ease-in-out; 
        transform: scale(0);
        -webkit-backface-visibility: hidden;
    }

    &:hover{
        box-shadow: 
		inset 0 0 0 110px rgba(255,255,255, 1),
		inset 0 0 0 16px rgba(255,255,255,0.8),
        0 1px 2px rgba(0,0,0,0.1);
        span{
            color: $primary-color;
        }
        &:before, &:after{
            opacity: 0;
            transform: scale(0);
        }
        
        .cta-bg{
            opacity: 1;
            transform: scale(1);
        }
    }
}
