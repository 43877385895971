// preload
body {
    &:not(.preload){
        .preload-block{
            animation: preloadshrink 0.5s ease forwards;
            -webkit-animation: preloadshrink 0.5s ease forwards;
            -moz-animation: preloadshrink 0.5s ease forwards;
            animation: preloadshrink 0.5s ease forwards;           
            -webkit-animation-delay: 0.5s;
            -moz-animation-delay: 0.5s;
            animation-delay: 0.5s;
            img{
                opacity: 0;
            }
        }
    }
}
.preload-block{
    position: fixed;
    top:0;
    left:0;
    background-color: $primary-color;
    width:100%;
    height:100vh;
    z-index: 10;
    overflow: hidden;
    img{
        width: 100px;
        @include vertical-center();
        left:0;
        right:0;
        margin:0 auto;
        transition: all 0.5s ease; 
    }    
}

@-webkit-keyframes preloadshrink {
    from { width: 100%; }
    to { width: 0%; }
}

@-moz-keyframes preloadshrink {
    from { width: 100%; }
    to { width: 0%; }
}

@keyframes preloadshrink {
    from { width: 100%; }
    to { width: 0%; }
}

// help and chat block
.fixed-blocks{
    opacity: 0;
    visibility: hidden;
    transition: background-color .3s, 
	opacity .5s, visibility .5s;
    &.show{
        opacity: 1;
		visibility: visible;
    }
    &.chat-block {
        display: none!important;
    }
}

.block-container{
    position: fixed;
    top:50%;
    background: rgb(38,109,178);
    background: linear-gradient(25deg, rgba(38,109,178,1) 0%, rgba(38,192,241,1) 100%);    
    color: $white;
    z-index: 1;
    transition: 0.25s all ease;
    @include breakpoint(small){
        width:38px;
    }
    @include breakpoint(medium){
        width:60px;
    }
    @include breakpoint(large){
        width: 14vw;
        max-width: 318px;
    }      
    .block-button{        
        font-weight: bold;
        cursor: pointer;        
        @include breakpoint(small){
            padding: 8px 6px;
            min-height: 38px;
            font-size: 11px;
            line-height: 11px;
        }
        @include breakpoint(medium){
            padding: 14px 12px;
            min-height: 60px;
            font-size: 14px;
            line-height: 16px;
        }        
        @include breakpoint(large){
            padding: 23px 20px;
            min-height: 70px;
            font-size: 16px;
            line-height: 24px;            
        }    
    }
}


//help block
.need-help-button {
    background-color: $penna-navy;
    border: 2px solid $white;
    border-radius: rem-calc(10) 0 0 rem-calc(10);
    color: $white;
    cursor: pointer;
    font-size: rem-calc(16);
    font-weight: bold;
    padding: rem-calc(10) rem-calc(16);
    position: fixed;
    right:0;
    top: 50%;
    transform: translateY(50%);
    z-index: 1;
    &:hover {
        color: $white;
    }
}
.need-help-block {
    .need-help-title {
        @include breakpoint(small){
            font-size: rem-calc(28);
            line-height: rem-calc(28);
        }
        @include breakpoint(medium){
            font-size: rem-calc(32);
            line-height: rem-calc(32);
        }
        @include breakpoint(large){
            font-size: rem-calc(40);
            line-height: rem-calc(40);
        }
    }
    ul{
        list-style: none;
        margin:0;
        li {
            h4 {
                margin:0;
            }
        }
    }
}

// chat block
.chat-block{
    .block-container{
        left:0;
        border-radius: 0 10px 10px 0;
        color: $white;
        img{
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            @include breakpoint(small){
                right: 6px;
                max-width: 28px;
            }
            @include breakpoint(medium){
                right: 10px;
                max-width: 42px;
            }
            @include breakpoint(large){
                right: 20px;
                max-width: 40px;
            }            
        }
    }
}

// speculative-container
.speculative-container {
    background-color: $penna-navy;
    color: $white;
    p{
        margin:0;
        @include breakpoint(large){
            font-size: rem-calc(18);
            line-height: rem-calc(24);
        }
        .personalform {
            cursor: pointer;
        }
        a {
            color: $white;
            font-weight: bold;
        }
        &:first-of-type {
            margin-bottom: rem-calc(16);
        }
    }
}

// kid link
.kid-link {
    p {
        margin-bottom: 40px;
        a {
            display: inline-block;
            font-weight: bold;
        }
    }
}

// roles search

.roles-search{
    border-top:1px solid $medium-gray;
    padding: rem-calc(15) 0;
    .search-input{
        position: relative;
        .hidden-label{
            display: block;
            height: 1px;
            overflow: hidden;
            width: 1px;
        }
        input{
            background-color: $light-gray;
            min-height: rem-calc(40);
            border:none;
            width:100%;
            padding: rem-calc(10) rem-calc(20) rem-calc(10) rem-calc(20);
        }
        img{
            position: absolute;
            top:50%;
            transform: translateY(-50%);
            right: rem-calc(10);
            max-width: rem-calc(25);
        }
    }
    .button{
        max-width: 100%;
        @include breakpoint(small){
            margin-top: rem-calc(10);
        }
        @include breakpoint(medium){
            margin-top:0;            
        }        
    }
}

// roles filter
.roles-filter{
    border-top:1px solid $medium-gray;
    padding: rem-calc(20) 0;
    .cell{
        .section-title-medium{            
            font-weight: 400;            
            margin:0;
            span{
                @include breakpoint(small){
                    display: none;
                }
                @include breakpoint(medium){
                    display: inline-block;
                }
            }
            &.active {
                font-weight: bold;
            }
        }
        &:nth-of-type(2){
            position: relative;
            &:before{
                display: block;
                content:"";
                position: absolute;
                top:0;
                left:0;
                width: 1px;
                height:100%;
                background-color: $medium-gray;
            }
            &:after{
                display: block;
                content:"";
                position: absolute;
                top:0;
                right:0;
                width: 1px;
                height:100%;
                background-color: $medium-gray;
            }  
        }
    }
}

// roles-accordion
.roles-accordion{
    .acc-item{
        border-bottom: 1px solid $medium-gray;
        .acc-link{
            border:none;
            color: $dark-gray;
            cursor: pointer;
            font-weight: 300;
            letter-spacing: -0.01em;                
            padding: rem-calc(20) rem-calc(32) rem-calc(20) 0;
            position: relative;  
            text-align: left;
            width: 100%;               
            .icon{
                @include vertical-center;
                left: initial;
                right:0;                
                &:before{
                    transition: all 0.25s ease;
                }
            }
            &:focus, &:hover{
                background: none;
                .icon{
                    background-color: $white;
                    &:before,&:after{
                        background-color: $dark-gray;
                    }
                }
            }
            &.open{
                .icon{
                    &:before{
                        height:0;
                    }
                }
            }   
        }
        .acc-content{
            border:none;
            display: none;
            padding: rem-calc(16) 0;
            .accordion-share{
                @include breakpoint(small){
                    text-align: center;
                }
                @include breakpoint(large){
                    text-align: right;                        
                }
                .share-icon{
                    display: inline-block;
                    padding: rem-calc(5);                        
                }
            }
            .role-details{
                p {
                    font-size: rem-calc(14)!important;
                    line-height: rem-calc(22)!important;
                    span {
                        font-size: rem-calc(14)!important;
                        line-height: rem-calc(22)!important; 
                    }
                }
                ul {
                    li {
                        font-size: rem-calc(14)!important;
                        line-height: rem-calc(22)!important;
                        span {
                            font-size: rem-calc(14)!important;
                            line-height: rem-calc(22)!important; 
                        }
                    }
                }
                a{
                    color: $dark-blue;
                    font-weight: bold;
                    text-decoration: underline;
                    span {
                        color: $dark-blue!important;
                        text-decoration: none!important;
                    }                        
                }
            }
            .button {
                margin: rem-calc(10) auto;
            }
        }
        &:first-of-type{
            border-top: 1px solid $medium-gray;               
        }
        .acc-link.open + .acc-content {
            display: block;
            position: relative;     
        }
    }
}

// event block
.events-container{
    .cell{
        margin-top:30px;
        .event-card{
            position: relative;
            display: block;
            background-size: cover;
            background-repeat: no-repeat;
            .event-date{
                position: absolute;
                top:12px;
                left:0;
                width:100%;
                text-align: center;
                text-shadow: 0 0 10px black;
                color: $white;
                font-weight: 700;
                padding: 12px;
                font-size: 24px;
                opacity: 1;
                transition: 0.25s opacity ease;
                @include breakpoint(medium only){
                    font-size: 18px;                    
                }                
            }
            .event-title{
                position: absolute;
                bottom:0;
                left:0;
                width:100%;
                min-height: 109px;
                transition: 0.25s min-height ease;
                @include breakpoint(small only){
                    min-height: 50vw;                       
                }
                .event-title-bg{
                    position: absolute;
                    top:0;
                    left:0;
                    width:100%;
                    height:100%;
                    background: rgb(0,84,165);
                    background: linear-gradient(45deg, rgba(0,84,165,1) 0%, rgba(0,84,165,1) 0%, rgba(0,160,154,1) 100%);
                    z-index: 0;
                    opacity: 0.9;          
                }
                .event-title-inner{
                    position: relative;
                    padding: 15px 18px;
                    color: $white;
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 22px;
                    @include breakpoint(medium only){
                        font-size: 14px;
                        line-height: 18px;                        
                    }            
                }
            }
            &:hover{
                .event-date{
                    opacity: 0;
                }
                .event-title{
                    min-height:100%;
                }
            }    
        
        }
        
        &:nth-of-type(1){
            .event-card{
                .event-title{
                    .event-title-bg{
                        background: rgb(0,84,165);
                        background: linear-gradient(45deg, rgba(0,84,165,1) 0%, rgba(0,160,154,1) 100%);
                    }
                } 
            }
        } 
        &:nth-of-type(2){
            .event-card{
                .event-title{
                    .event-title-bg{
                        background: rgb(237,0,140);
                        background: linear-gradient(25deg, rgba(237,0,140,1) 0%, rgba(0,196,231,1) 100%);
                    }
                } 
            }
        } 
        &:nth-of-type(3){
            .event-card{
                .event-title{
                    .event-title-bg{
                        background: rgb(0,236,27);
                        background: linear-gradient(25deg, rgba(0,236,27,1) 0%, rgba(41,159,153,1) 100%);
                    }
                } 
            }
        }                        

    }
}

// kid content
.kid-content {
    a {
        text-decoration: underline;
    }
    table {
        margin-bottom: rem-calc(20);
        td {
            border: 1px solid $black;
            padding: rem-calc(10);
            p {
                margin:0;
            }
        }
    }
}


