
@import 'foundation';
@import '~foundation-sites/scss/util/util';
@import '~foundation-sites/scss/util/mixins';

@import '~aos/dist/aos.css';

@import './components/base';
@import './components/header';
@import './components/footer';
@import './components/blocks';
@import './components/form';
@import './components/colors';
@import './components/typography';
@import './components/buttons';
@import './components/cookies';

@import './functions';
@import './fonts';

@include padding-list();
@include margin-list();

#skip-to-content {
	padding: 1em 2em;
	position: absolute;
	top: -100%;
	left: 0		;
	width: 100%;
	z-index: 1000;
	&:focus {
	  position: absolute;
	  top: 0;
	  left:0;
	  background: $focus-color;
	}  
}

a:focus, button:focus {
	outline: 1px solid transparent!important;
	background-color: $focus-color!important;
	color: $black!important;	
    box-shadow: 0 0 $focus-color, 0 4px $black!important;
}

// gradients

.gradient-main{
	background: $primary-color;
	background: linear-gradient(90deg, $primary-color 0%, $primary-color 0%, $secondary-color 100%);
}






