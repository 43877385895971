// typography

h1,h2,h3,h4,h5,h6{
	font-weight: 700;
	letter-spacing: -0.025em;
}

.section-title-large {
	@include breakpoint(small){
		font-size: rem-calc(38);
		line-height: rem-calc(42);
	}
	@include breakpoint(medium){
		font-size: rem-calc(60);
		line-height: rem-calc(60);
	}
	@include breakpoint(large){
		font-size: rem-calc(75);
		line-height: rem-calc(82);
	}	
}

.section-title-medium {
	@include breakpoint(small){
		font-size: rem-calc(18);
		line-height: rem-calc(18);
	}
	@include breakpoint(medium){
		font-size: rem-calc(20);
		line-height: rem-calc(20);
	}
	@include breakpoint(large){
		font-size: rem-calc(28);
		line-height: rem-calc(28);
	}	
}

.section-title-small {
	@include breakpoint(small){
		font-size: rem-calc(16);
		line-height: rem-calc(16);
	}
	@include breakpoint(medium){
		font-size: rem-calc(18);
		line-height: rem-calc(18);
	}
	@include breakpoint(large){
		font-size: rem-calc(19);
		line-height: rem-calc(19);
	}	
}

.light{
	font-weight: 300;
}
.medium{
	font-weight: 500;
}
.bold{
	font-weight: 700;
}

.underline{
	text-decoration: underline;
}

.title-shadow{
	text-shadow: 0 0 10px rgba(0,0,0,0.3);
	span{
		font-weight: bold;
	}
}

p{
	font-weight: 300;
	letter-spacing: -0.01em;
	@include breakpoint(small){
		font-size: rem-calc(16);
		line-height: rem-calc(20);
	}
}

.intro-text{
	p{
		@include breakpoint(small){
			font-size: rem-calc(20);
			line-height: rem-calc(32);
			@include breakpoint(landscape){
				font-size: rem-calc(24);
				line-height: rem-calc(48);
			}
		}
		@include breakpoint(medium){
			font-size: rem-calc(24);
			line-height: rem-calc(48);
			@include breakpoint(landscape){
				font-size: rem-calc(22);
				line-height: rem-calc(38);
			}
		}
		@include breakpoint(large){
			font-size: rem-calc(24);
			line-height: rem-calc(48);			
		}		
	}
}